<app-dialog-header
  [title]="editExistingUser ? userForm.controls.username.value : ('appLabels.form.user.title.profile' | translate)"
  [subtitle]="editExistingUser ? ('appLabels.form.user.title.edit' | translate) : ('appLabels.form.user.title.new' | translate)">
</app-dialog-header>
<form [formGroup]="userForm" class="form-content user-form">
  <app-common-input
    [class]="editExistingUser ? 'small-field' : 'large-field' "
    [control]="userForm.controls.username"
    [validatorErrors]="[usernamePatternErrorMsg]"
    [placeholder]="'appLabels.form.user.general.username' | translate"></app-common-input>
  <app-common-input
    [class]="editExistingUser ? 'medium-field' : 'large-field' "
    [control]="userForm.controls.email"
    [placeholder]="'appLabels.form.user.general.email' | translate"
    [validatorErrors]="[emailPatternErrorMsg]"></app-common-input>
  <app-common-input
    class="small-field"
    [control]="userForm.controls.givenName"
    [placeholder]="'appLabels.form.user.general.givenName' | translate"></app-common-input>
  <app-common-input
    class="medium-field"
    [control]="userForm.controls.familyName"
    [placeholder]="'appLabels.form.user.general.familyName' | translate"></app-common-input>
  <app-password-form
    *ngIf="!editExistingUser"
    (password)="this.userForm.controls.password.setValue($event)"></app-password-form>
  <mat-form-field class="large-field">
    <mat-label>{{'appLabels.form.user.general.language' | translate}}</mat-label>
    <mat-select [formControl]="userForm.controls.lang">
      <mat-option *ngFor="let lang of languagesList" [value]="lang.language"> {{ lang.value }} </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="large-field" *ngIf="loggedInUserIsSysAdmin$ | async">
    <mat-slide-toggle
      [checked]="userForm.controls.systemAdmin.value"
      (change)="userForm.controls.systemAdmin.setValue(!userForm.controls.systemAdmin.value)"
      [disabled]="editExistingUser">
      {{ 'appLabels.form.user.general.sysAdmin' | translate }}
    </mat-slide-toggle>
  </div>
</form>

<app-user-form [user]="user" (afterFormInit)="form = $event"></app-user-form>

<div class="form-panel">
  <button color="primary" mat-button mat-dialog-close>{{ 'appLabels.form.action.cancel' | translate }}</button>
  <button
    mat-raised-button
    color="primary"
    appLoadingButton
    [isLoading]="isLoading$ | async"
    [disabled]="!form?.valid || (isLoading$ | async)"
    (click)="createUser()">
    {{'appLabels.form.action.submit' | translate}}
  </button>
</div>
